import * as React from 'react';
import Box from '@mui/material/Box';
import * as Styles from './KeyToActionBar.styles';
import { Investor_Platform_URL } from 'links';
import { useScrollTrigger } from '@mui/material';
import axios from 'axios';

interface Supply {
  result: string; // adjust the type according to your data structure
  // other properties...
}
export const TOKEN_CONTRACT_ADDRESS =
  '0x7fC0c917447aB69E5372E1d46B39caCA87595952';

const KeyToActionBar = (): JSX.Element => {
  const [tokenSupply, setTokenSupply] = React.useState<Supply | null>(null);
  const [tokenBalance, setTokenBalance] = React.useState<Supply | null>(null);

  React.useEffect(() => {
    (async () => {
      const tokenSupplyData = await getTokenSupply();
      const tokenBalanceData = await getTokenBalance();
      setTokenSupply(tokenSupplyData);
      setTokenBalance(tokenBalanceData);
    })();

    // Don't display cookie button when the KeyToActionBar is shown
    const cookieBtn = document.getElementById('Coi-Renew');
    if (cookieBtn) {
      cookieBtn.style.display = 'none';
    }

    return () => {
      if (cookieBtn) {
        cookieBtn.style.display = 'block';
      }
    };
  }, []);

  const tokenBalanceUnresolved = isNaN(Number(tokenBalance?.result));
  const tokenBalanceResult = tokenBalanceUnresolved
    ? 0
    : Number(tokenBalance?.result);
  let tokenSupplyResult = isNaN(Number(tokenSupply?.result))
    ? 0
    : Number(tokenSupply?.result);

  if (tokenBalanceUnresolved) {
    tokenSupplyResult = 0;
  }

  const tokensLeft = tokenBalance ? tokenBalanceResult : 0;
  const totalTokens = tokenSupply ? tokenSupplyResult : 0;
  const tokensSold = totalTokens - tokensLeft;
  let calculatedPercent = Math.round(
    (tokensSold / (tokensLeft + tokensSold)) * 100,
  );

  if (tokenBalanceUnresolved) {
    calculatedPercent = 0;
  }

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 400,
  });

  const options = { useGrouping: true, maximumFractionDigits: 0 };
  const formatted = (stringNr: number) =>
    stringNr.toLocaleString(undefined, options).replace(/,/g, '.');

  return (
    <Box
      display="flex"
      position="sticky"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      sx={{
        backgroundColor: '#F8F8F8',
        width: '100%',
        height: '96px',
        bottom: 0,
        left: 0,
        zIndex: 1900,
        opacity: trigger ? 1 : 0,
        pointerEvents: trigger ? 'all' : 'none',
        transition: 'opacity 0.1s',
        boxShadow: '0px -2px 7px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Styles.ProgressStyles calculatedPercent={calculatedPercent}>
        <div className="progress-element progress-element--html">
          <div className="progress-label">
            <p>Sold tokens</p>
            <p className="token-sold">{formatted(tokensSold)}</p>
          </div>

          <div className="progress-container">
            <progress max="100" value="0" />
          </div>

          <p className="tokens-left">
            <span className="token-count">{formatted(tokensLeft)}</span>
            Tokens left
          </p>
        </div>
        <Styles.ActionButton
          href={Investor_Platform_URL + '/sign-in'}
          variant="contained"
          color="alternate"
        >
          Buy
        </Styles.ActionButton>
      </Styles.ProgressStyles>
    </Box>
  );
};

export default KeyToActionBar;

const ETHERSCAN_BASE_ADDRESS = 'https://api.etherscan.io/api';
const ETHERSCAN_API_KEY = process.env.GATSBY_ETHERSCAN_API_KEY || '';

const STATIC_PROPERTIES = {
  contractaddress: TOKEN_CONTRACT_ADDRESS,
  apikey: ETHERSCAN_API_KEY,
};

async function getTokenSupply() {
  try {
    const response = await axios.get(ETHERSCAN_BASE_ADDRESS, {
      params: {
        module: 'stats',
        action: 'tokensupply',
        ...STATIC_PROPERTIES,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
}
async function getTokenBalance() {
  try {
    const response = await axios.get(ETHERSCAN_BASE_ADDRESS, {
      params: {
        module: 'account',
        action: 'tokenbalance',
        address: '0x626C596c4e0CF6AC747002faFd14976c22D54ab2',
        tag: 'latest',
        ...STATIC_PROPERTIES,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
}
