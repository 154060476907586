import React from 'react';
import { KeyValueList, PictureParagraph, Table } from '../components';
import grevToken from 'images/Properties/grevToken.svg';
import { Box } from '@mui/system';
import Container from 'components/Container';
import { TabTitle } from './Styles';
import Piechart from 'images/Properties/tokenomics/piechart.svg';
import Disclaimer from '../components/Disclaimer';
import { TOKEN_CONTRACT_ADDRESS } from 'layouts/Properties/components/KeyToActionBar/KeyToActionBar';

const TokenomicsTab = (): JSX.Element => {
  const KeyValueListContent = {
    grev55token: {
      id: 1,
      title: 'GREV 55 Token',
      rows: [
        {
          id: 1,
          key: 'GREV 55 token logo',
          value: grevToken,
        },
        {
          id: 2,
          key: 'Blockchain',
          value: 'Ethereum, ERC-1404',
        },
        {
          id: 3,
          key: 'Token issuer',
          value: 'GREV 55 A/S CVR nr. 44047594',
        },
        {
          id: 4,
          key: 'Token contract address',
          value: TOKEN_CONTRACT_ADDRESS,
          link: `https://etherscan.io/token/${TOKEN_CONTRACT_ADDRESS}`,
        },
        {
          id: 5,
          key: 'Total supply',
          value: '2.750.000',
          tooltip: ['Tokenized 2.502.500', 'Not tokenized 247.500'],
        },
        {
          id: 6,
          key: 'Price per token',
          value: '€ 0,73',
          tooltip: [
            'Nominal price per token during the Digital Share Offering.',
          ],
        },
        {
          id: 7,
          key: 'Underlying asset value',
          value: '2.0 million Euro (for ready property)',
        },
        {
          id: 8,
          key: 'Min. investment',
          value: '135 tokens',
        },
        {
          id: 9,
          key: 'Max. investment',
          value: '107.800 tokens',
        },
        {
          id: 10,
          key: 'Token price increase',
          value: '10% yearly',
          tooltip: [
            'Token appreciation is a measure of the estimated increase in the value of a property over a 12 month period, combined with the token selling price in the aftermarket.',
          ],
        },
        {
          id: 11,
          key: 'Cash return per token',
          value: '€ 0,025',
          tooltip: [
            'Cash per token is the estimated return from yearly rent collected and distributed proportionally between the token owners. For example, a property collects € 70,000 as a rent dividend, equally distributed between 2.750.000 tokens, each token receives € 0.025. The amount depends on the excess income from the property being rented out. ',
          ],
        },
        {
          id: 12,
          key: 'Custody options',
          value: 'Self custody',
        },
      ],
    },
    tokenSamples: {
      id: 1,
      title: 'GREV55 token logo',
      rows: [
        {
          id: 1,
          key: grevToken,
          value: grevToken,
        },
      ],
    },
  };

  const TableContent = {
    tokenDistribution: {
      id: 1,
      title: 'Token distribution',
      rows: [
        /* prettier-ignore */
        ['','Token allocation', 'Number of tokens', 'Price per token', 'Funds raised', 'Comments'],
        /* prettier-ignore */
        ['Public sale','80%', '2.200.000', '€ 0.73', '€ 1,606,000', 'Locked until the fundraising is completed. Full refund if not completed.'],
        /* prettier-ignore */
        ['Early partners, Advisors','11%', '302.500', '', '', 'Locked until the renovation is completed.  '],
        /* prettier-ignore */
        // eslint-disable-next-line quotes
        ['Platform RESERVED','9%', '247.500', '', '', `Not tokenized. Can't be traded on the platform.`],
        /* prettier-ignore */
        ['Σ Total','100%', '2.750.000', '', '', ''],
      ],
    },
  };

  const pictureParagraphContent = {
    pieChart: {
      id: 1,
      title: '',
      altTxt: '',
      alt: 'A pie chart showing token distribution to investors, partners and reserved.',
      imageSrc: Piechart,
    },
  };

  return (
    <Container
      display="flex"
      sx={{
        flexDirection: { md: 'column', sm: 'column', xs: 'column' },
        paddingTop: { md: '14px', sm: '14px', xs: '14px' },
        margin: 0,
      }}
    >
      <TabTitle>Tokenomics</TabTitle>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', sm: 'column', xs: 'column' },
          justifyContent: 'space-between',
          gap: '52px',
        }}
      >
        <KeyValueList content={KeyValueListContent.grev55token} />
        <PictureParagraph
          content={pictureParagraphContent.pieChart}
          sx={{
            maxWidth: { md: '42%', sm: '100%', xs: '100%' },
            paddingTop: { md: '89px', sm: '24px', xs: '24px' },
            justifyContent: 'center',
          }}
        />
      </Box>
      <Table content={TableContent.tokenDistribution} />
      {/* <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '52px',
        }}
      >
        <KeyValueList
          content={KeyValueListContent.tokenSamples}
          keyIMGHeight={150}
          valueIMGHeight={150}
          valueIMGStyle={{ filter: ' grayscale(1)' }}
        />
      </Box> */}
      <Disclaimer />
    </Container>
  );
};

export default TokenomicsTab;
